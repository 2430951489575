/* global Rails */

const App = {}
window.App = App
;(function () {
  // Executed one time at first page loading
  this.start = function () {}

  // Executed on each page load
  this.init = function () {
    const self = this
    self.notifications.init()
    self.modal.init()

    // ALLOW SUBMIT VIA TEXTAREA
    document.body.addEventListener('keydown', e => {
      if (
        e.target.tagName === 'TEXTAREA' &&
        !e.target.matches('#chat_content, .js-not-auto-submit') &&
        e.keyCode === 13 &&
        (e.metaKey || e.ctrlKey)
      ) {
        Rails.fire(e.target.form, 'submit')
      }
    })
  }
}).apply(App)

document.addEventListener('DOMContentLoaded', () => {
  App.start()
})

document.addEventListener('turbo:load', () => {
  App.init()
})

document.addEventListener('turbo:frame-render', () => {
  App.notifications.init()
})
