const initAlpine = async () => {
  // Skip if no Alpine components
  if (!document.querySelector('[x-data], [x-init], [x-show]')) return

  try {
    const [{ default: intersect }, { default: AsyncAlpine }, { default: Alpine }, { default: collapse }] = await Promise.all(
      [import('@alpinejs/intersect'), import('async-alpine'), import('alpinejs'), import('@alpinejs/collapse')]
    )

    Alpine.plugin(AsyncAlpine)
    Alpine.plugin(collapse)
    Alpine.plugin(intersect)

    // Load components on demand
    const componentModules = import.meta.glob('../../components/**/*.js', { eager: false })
    const alpineModules = import.meta.glob('../alpine/**/*.js', { eager: false })

    for (const [name, component] of Object.entries(componentModules)) {
      Alpine.asyncData(identifierForGlobKey(name), component)
    }

    for (const [name, component] of Object.entries(alpineModules)) {
      Alpine.asyncData(identifierForGlobKey(name, false), component)
    }

    Alpine.start()
  } catch (e) {
    console.warn('Alpine failed to load:', e)
  }
}

function identifierForGlobKey(key, controller = true) {
  const COMPONENTS_FILENAME_REGEX = /^(?:.*?(?:components)\/|\.?\.\/)?(.+)(?:[/_-](component)\..+?)$/
  const REGULAR_FILENAME_REGEX = /^(?:.*?(?:alpine)\/|\.?\.\/)?(.+)(?:\..+?)$/
  const logicalName = (key.match(controller ? COMPONENTS_FILENAME_REGEX : REGULAR_FILENAME_REGEX) || [])[1]
  if (logicalName) return logicalName.replace(/\//g, '__')
}

// Initialize after critical content loads
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', initAlpine)
} else {
  initAlpine()
}
